import { createError } from "#imports";
import {
  isPathContainer,
  parseCtuAndMaybeCargoIndexFromField,
  PathContextBuilder,
  type PathContextBuilder as PathContextBuilderType,
} from "~/utils/pathHelpers";
import { z } from "zod";
import type { Prettify } from "~/utils/helpers";

const weightSchema = z.object({
  unit: z
    .string()
    .toLowerCase()
    .pipe(z.enum(["tons", "kg", "g", "l", "ml"])),
  value: z.number({ coerce: true }),
});
const weightDetailsSchema = z.object({
  "net-weight": weightSchema.optional(),
  "gross-weight": weightSchema.optional(),
  capacity: weightSchema.optional(),
  "tare-weight": weightSchema.optional(),
});

const dgCargoSchema = z.object({
  "un-number": z.string().min(4).max(4),
  "proper-shipping-name": z.string().min(1),
  "technical-name": z.string().optional(),
  class: z.string().min(1),
  "subsidiary-hazard": z.array(z.string()).optional(),
  "packing-group": z.string().optional(),
  flashpoint: z
    .object({
      $schema: z.literal("#/definitions/temperature").optional(),
      value: z.number(),
      units: z
        .string()
        .transform((x) => x.toLowerCase())
        .pipe(z.enum(["c", "f", "unknown"]))
        .default("c")
        .catch("unknown"),
    })
    .optional(),
  "is-marine-pollutant": z.boolean().default(false),
  "is-limited-quantity": z.boolean().default(false),
  "is-excepted-quantity": z.boolean().default(false),
});

export const bookingSchema = z.object({
  $schema: z.string().min(1),
  reference: z.string().min(1),
  description: z.string().optional(),
  "imdg-amendment": z.number().int(),
  sailings: z.array(
    z.object({
      $id: z.string().optional(),
      type: z.enum(["Cargo", "Passenger"]),
      vessel: z.object({
        code: z.string(),
        name: z.string().optional(),
        flag: z.string().optional(),
        operator: z
          .object({ code: z.string().min(1), name: z.string().optional() })
          .optional(),
      }),
      stages: z.array(
        z.object({
          $id: z.string().optional(),
          type: z.enum([
            "Origin",
            "Transit",
            "Import",
            "Export",
            "Tranship",
            "Final",
          ]),
          port: z
            .object({ code: z.string().min(1), name: z.string().optional() })
            .optional(),
          location: z
            .object({ code: z.string().min(1), name: z.string().optional() })
            .optional(),
          country: z
            .object({
              code: z.string().min(1).optional(),
              name: z.string().optional(),
            })
            .optional(),
        })
      ),
    })
  ),
  "cargo-transport-units": z
    .array(
      z.object({
        $id: z.string().optional(),
        type: z
          .enum([
            "Open",
            "Closed",
            "Bulk",
            "BulkOpen",
            "BulkClosed",
            "Heated",
            "Reefer",
            "Tank",
            "NOT PROVIDED",
          ])
          .optional(),
        cargo: z
          .array(
            z
              .object({
                $id: z.string().optional(),
                $schema: z.string(),
                code: z.string().optional(),
                description: z
                  .array(
                    z.object({
                      $id: z.string().optional(),
                      text: z.string().min(1),
                      source: z.string().optional(),
                    })
                  )
                  .min(1),
                "weight-details": weightDetailsSchema.nullable().optional(),
                packaging: z
                  .object({
                    ref: z.string().optional(),
                    code: z.string().min(1),
                    count: z.number().gte(1).default(1),
                    description: z.string().optional(),
                    inner: z
                      .object({
                        code: z.string().optional(),
                        count: z.number().gte(1).default(1),
                      })
                      .optional(),
                  })
                  .optional(),
              })
              .and(
                z
                  .object({
                    $schema: z
                      .enum([
                        "http://detect.hazcheck.com/validation/shipment.schema.json#commodity",
                        "#commodity",
                      ])
                      .optional(),
                  })
                  .or(
                    z.object({
                      $schema: z
                        .enum([
                          "http://detect.hazcheck.com/validation/shipment.schema.json#dangerous-cargo",
                          "#dangerous-cargo",
                        ])
                        .optional(),
                      ...dgCargoSchema.shape,
                    })
                  )
                  .or(
                    z.object({
                      $schema: z.enum([
                        "http://detect.hazcheck.com/validation/shipment.schema.json#dangerous-cargo-multiple",
                        "#dangerous-cargo-multiple",
                      ]),
                      declarations: z.array(dgCargoSchema).optional(),
                    })
                  )
              )
          )
          .min(1),
        documents: z
          .array(
            z.object({ type: z.string().min(1), content: z.string().min(1) })
          )
          .optional(),
      })
    )
    .min(1),
  parties: z.record(
    z.object({ code: z.string().min(1), name: z.string().min(1) })
  ),
});

export type BackendBookingViewModel = z.infer<typeof bookingSchema>;

export type Parties = BackendBookingViewModel["parties"];

export type PartyViewModel = {
  path: string;
  _raw: any;
  displayName: string;
  index: number;
};
export type Description = BackendBookingCargoViewModel["description"][number];

export type BackendBookingCTUViewModel =
  BackendBookingViewModel["cargo-transport-units"][number];

export type WithUnknownExtraProperties<T> = T & {
  [key: string]: string | number | boolean | undefined;
};

type BookingCargo = BackendBookingCTUViewModel["cargo"][number];

export type BackendBookingCargoViewModel = Prettify<BookingCargo>;

export const isMetadataBookingKey = (key: string) => {
  return key.startsWith("$");
};

export const shouldShowBookingProperty = (key: string) => {
  if (
    key === SAILINGS_KEY_IN_BOOKING_VIEW_MODEL ||
    key === PARTIES_KEY_IN_BOOKING_VIEW_MODEL ||
    key === CONTAINERS_KEY_IN_BOOKING_VIEW_MODEL
  )
    return false;
  if (isMetadataBookingKey(key)) return false;

  return true;
};

const PATH_ROOT = "$";

export const bookingPathMatchesField = (
  path: string,
  field: string,
  strict = false
) => {
  if (strict && isPathContainer(path)) {
    const indexes = parseCtuAndMaybeCargoIndexFromField(field);
    if (indexes === null || indexes[1] !== null) return false;
    return bookingPathMatchesFieldInner(path, field);
  } else {
    return bookingPathMatchesFieldInner(path, field);
  }
};

const bookingPathMatchesFieldInner = (path: string, field: string) => {
  if (path !== PATH_ROOT) {
    return field.startsWith(path);
  }

  // path at top level of booking, so we need to filter out keys
  const fieldParts = field.split(".");
  if (fieldParts.length < 2) return false;
  let key = fieldParts[1];

  // if it's an array access, remove it e.g. cargo-transport-units[0]
  const indexOfArrayAccessAttempt = key.indexOf("[");
  if (indexOfArrayAccessAttempt > -1) {
    key = key.substring(0, indexOfArrayAccessAttempt);
  }

  const result = shouldShowBookingProperty(key);

  return result;
};

export type BookingCargoPackagingViewModel = Prettify<
  Required<BackendBookingCTUViewModel["cargo"][number]>["packaging"]
>;

export const SAILINGS_KEY_IN_BOOKING_VIEW_MODEL = "sailings";
export type BackendBookingSailingViewModel = Prettify<
  BackendBookingViewModel[typeof SAILINGS_KEY_IN_BOOKING_VIEW_MODEL][number]
>;

export type BackendBookingSailingStageViewModel = Prettify<
  BackendBookingSailingViewModel["stages"][number]
>;

export const PARTIES_KEY_IN_BOOKING_VIEW_MODEL = "parties";
export type BookingPartyViewModel = Prettify<
  BackendBookingViewModel[typeof PARTIES_KEY_IN_BOOKING_VIEW_MODEL][number]
>;

export const CONTAINERS_KEY_IN_BOOKING_VIEW_MODEL = "cargo-transport-units";

type HasId = { $id?: string };

function createPath(
  indexInBooking: number,
  path: PathContextBuilderType,
  propertyKey: string
) {
  return new PathContextBuilder(path)
    .withProperty(propertyKey)
    .withIndex(indexInBooking);
}

type BaseModel<T extends HasId> = {
  path: string;
  active: boolean;
  _raw: T;
  displayName: string;
  index: number;
};

export type BookingViewModel = {
  _raw: BackendBookingViewModel;
  containers: BookingContainerViewModel[];
  sailings: BookingSailingViewModel[];
  path: string;
  bookingId: string | null;
};

const createCargo = (
  cargo: BackendBookingCargoViewModel[],
  path: PathContextBuilderType
) => cargo.map((x, i) => createBookingCargoViewModel(x, i, path));

export type BookingContainerViewModel =
  BaseModel<BackendBookingCTUViewModel> & {
    cargo: BookingCargoViewModel[];
  };

export type BookingCargoViewModel = BaseModel<BackendBookingCargoViewModel> & {
  isDg: boolean;
};

export type BookingSailingViewModel =
  BaseModel<BackendBookingSailingViewModel> & {
    stages: BookingSailingStageViewModel[];
    type: "Cargo" | "Passenger";
    vesselDisplayName: string;
  };

export type BookingSailingStageViewModel =
  BaseModel<BackendBookingSailingStageViewModel> & {
    type: "Origin" | "Transit" | "Import" | "Export" | "Tranship" | "Final";
    country: BackendBookingSailingStageViewModel["country"];
  };

export function createBookingViewModel(
  booking: BackendBookingViewModel
): BookingViewModel {
  return {
    _raw: booking,
    bookingId: booking.reference || null,
    containers: booking["cargo-transport-units"].map((x, i) =>
      createBookingContainerViewModel(x, i, new PathContextBuilder())
    ),
    sailings: booking.sailings.map((x, i) =>
      createBookingSailingViewModel(x, i, new PathContextBuilder())
    ),
    path: "$",
  };
}

export function createBookingContainerViewModel(
  container: BackendBookingCTUViewModel,
  indexInBooking: number,
  path: PathContextBuilderType
): BookingContainerViewModel {
  const displayName = container.$id ?? `Container ${indexInBooking + 1}`;
  const pathBuilder = createPath(
    indexInBooking,
    path,
    CONTAINERS_KEY_IN_BOOKING_VIEW_MODEL
  );
  return {
    path: pathBuilder.build(),
    active: true,
    _raw: container,
    displayName,
    index: indexInBooking,
    cargo: createCargo(container.cargo, pathBuilder),
  };
}

function getSailingDisplayName(
  sailing: BackendBookingSailingViewModel,
  indexInBooking: number
) {
  if (sailing.$id) return sailing.$id;
  if (sailing.type === "Cargo") {
    return `Sailing ${indexInBooking + 1}`;
  } else {
    return `Sailing ${indexInBooking + 1}`;
  }
}

function getVesselDisplayName(sailing: BackendBookingSailingViewModel) {
  return sailing.vessel.name || sailing.vessel.code;
}

function getSailingStageDisplayName(
  sailingStage: BackendBookingSailingStageViewModel,
  indexInSailing: number
) {
  if (sailingStage.$id) return sailingStage.$id;
  return `${sailingStage.type} Stage ${indexInSailing + 1}`;
}

export function createBookingCargoViewModel(
  cargo: BackendBookingCargoViewModel,
  indexInContainer: number,
  path: PathContextBuilderType
): BookingCargoViewModel {
  const displayName = cargo.$id ?? `Cargo ${indexInContainer + 1}`;
  const pathBuilder = createPath(indexInContainer, path, "cargo");
  return {
    path: pathBuilder.build(),
    active: true,
    _raw: cargo,
    displayName,
    index: indexInContainer,
    isDg: Boolean(
      "un-number" in cargo ||
        ("declarations" in cargo && cargo.declarations?.length)
    ),
  };
}

export function createBookingSailingViewModel(
  sailing: BackendBookingSailingViewModel,
  indexInBooking: number,
  path: PathContextBuilderType
): BookingSailingViewModel {
  const displayName = getSailingDisplayName(sailing, indexInBooking);
  const pathBuilder = createPath(indexInBooking, path, "sailings");
  return {
    path: pathBuilder.build(),
    active: true,
    _raw: sailing,
    displayName,
    index: indexInBooking,
    type: sailing.type,
    vesselDisplayName: getVesselDisplayName(sailing),
    stages: sailing.stages.map((x, i) =>
      createBookingSailingStageViewModel(x, i, pathBuilder)
    ),
  };
}

export function createBookingSailingStageViewModel(
  sailingStage: BackendBookingSailingStageViewModel,
  indexInSailing: number,
  path: PathContextBuilderType
): BookingSailingStageViewModel {
  const displayName = getSailingStageDisplayName(sailingStage, indexInSailing);
  const pathBuilder = createPath(indexInSailing, path, "stages");
  return {
    path: pathBuilder.build(),
    active: true,
    _raw: sailingStage,
    displayName,
    index: indexInSailing,
    country: sailingStage.country,
    type: sailingStage.type,
  };
}

function getEntity<T extends { _raw: HasId }>(entities: T[], id: string): T {
  let entity = entities.find((entity) => entity._raw.$id === id);
  if (entity) return entity;

  const index = parseInt(id) - 1;
  if (isNaN(index) || index < 0 || index >= entities.length) {
    throw new Error(`ctu index out of bounds: ${index}, id: ${id}`);
  }
  entity = entities.at(index);
  if (entity === undefined)
    throw createError("no container at ctuIndex: " + index);
  return entity;
}

export const getContainer = (booking: BookingViewModel, ctuId: string) =>
  getEntity(booking.containers, ctuId);

export const getCargo = (
  container: BookingContainerViewModel,
  cargoId: string
) => getEntity(container.cargo, cargoId);

export const getSailing = (booking: BookingViewModel, sailingId: string) =>
  getEntity(booking.sailings, sailingId);
